<template>
	<div class="mem_container exit">
		<div class="mem_info_contents">
			<div class="contents-body cf">
				<div class="contents-body-box">
					<img src="/images/mem/hipro_mem_img.png" alt="하이프로"/>
					<div class="guide-text">
						<span>그동안 하이프로를 이용해 주셔서 감사합니다.</span><br/>
						회원님의 탈퇴가 완료되었습니다. 앞으로 더욱 좋은 모습으로 찾아뵙겠습니다.
					</div>
					<div class="confirm-btn" @click="pageMove()">확인</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	methods : {
		pageMove() {
			location.href = '/';
		}
	}
}
</script>